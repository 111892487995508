@use "../../scss/variables" as variables;
@use "../../scss/functions" as functions;

.wrapper-modulos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //gap: functions.toRem(30);
    padding: 0 functions.toRem(10);
}

.list-pts-monitoria {
    display: flex;
    flex-wrap: wrap;
    padding: 0 functions.toRem(10);

    .card-monitoria {
        margin-right: functions.toRem(10);
        margin-left: functions.toRem(10);
        height: 250px;
        border-radius: 12px;
        // border: 1px solid variables.$borderOpaque;
        margin-bottom: functions.toRem(20);
        width: 100%;
        //background-color: lightgray;

        &.positive {
            border-bottom: 3px solid variables.$positive;
            box-shadow: 1px 1px 6px 1px variables.$borderPositive;
            //background-color: #e6f2ec;
        }

        &.negative {
            border-bottom: 3px solid variables.$negative;
            box-shadow: 1px 1px 6px 1px variables.$borderNegative;
            //background-color: #faf1f0;
        }

        &.accent {
            border-bottom: 3px solid variables.$accent;
            box-shadow: 1px 1px 6px 1px variables.$backgroundAccent;
            //background-color: #ebf1fc;
        }

        &.offline {
            border-bottom: 3px solid variables.$offline;
            box-shadow: 1px 1px 6px 1px variables.$borderOffline;
        }

        @media screen and (min-width: variables.$md) {
            width: calc((100% / 3) - functions.toRem(20));
        }

        @media screen and (min-width: variables.$md) {
            width: calc((100% / 3) - functions.toRem(20));
        }
    }

    .card-monitoria__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //height: 100%;
        padding: functions.toRem(16);
        border: none;
        align-items: center;
        //background-color: none;

        > header {
            margin-bottom: functions.toRem(10);
            //padding-bottom: functions.toRem(10);
            //border-bottom: 1px solid variables.$borderOpaque;
            display: flex;
            justify-content: space-between;

            > div {
                text-align: center;
                flex-grow: 1;
            }
        }

        .figure {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .figure__icon {
            width: 90px;
            height: 90px;

            img {
                max-width: 100%;
            }
        }

        .figure__text {
            width: 90px;
            text-align: center;
        }

        .infoPost {
            width: 100%;
            font-size: 11px;
            text-align: center;
            color: grey;
        }

        .footerButtons {
            display: flex;
            justify-content: center;
            gap: 1rem;
            margin-top: 0.25rem;
        }

        .contentCardRaiox {
            margin-bottom: functions.toRem(10);
            width: 100%;
            display: flex;
            justify-content: center;
        }

        .raioxPaint__content {
            display: flex;
            justify-content: center;
            gap: 5px;
            align-items: center;
        }

        .raioxPaint {
            display: flex;
            width: 12px;
            height: 12px;
            border: 2px;
            background-color: lightgray;
            border-radius: 50%;

            &.ativo {
                //border-bottom: 3px solid variables.$positive;
                //box-shadow: 1px 1px 6px 1px variables.$borderPositive;
                background-color: variables.$positive;
            }
        }

        .raioxPaintAtual {
            display: flex;
            width: 12px;
            height: 12px;
            border: 2px;
            background-color: lightgray;
            border-radius: 50%;

            &.positive {
                background-color: variables.$positive;
            }

            &.negative {
                background-color: variables.$negative;
            }

            &.accent {
                background-color: variables.$accent;
            }
        }

        .tooltip {
            position: relative;
            display: inline-block;
        }

        .tooltip .tooltiptext {
            //width: 50px;
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 5px;
            padding: 5px;
            position: relative;
            z-index: 1;
            bottom: 125%;
            //right: 15%;
            //left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: opacity 0.3s, visibility 0.3s;
        }

        .raioxPaint:hover .tooltip .tooltiptext {
            visibility: visible;
            opacity: 0.7;
        }
    }
}