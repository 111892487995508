// @use "../../../scss/variables" as variables;
// @use "../../../scss/functions" as functions;

// .search-list {
//   width: 597px;
//   display: flex;
//   display: flex;
//   flex-direction: column;
// }


// .item-list {
//   display: flex;
//   flex-wrap: wrap;
//   padding: functions.toRem(10);
//   border: 1px solid variables.$primaryA;
//   border-left: 8px solid ${status === 0 ? theme.colors.danger : theme.colors.success};
//   background: ${theme.colors.white};
//   transform: translateX(0);
//   transition: transform .10s;
//   opacity: ${disabled ? "0.2" : "1"};

//   &.danger {}
//   &.success {}
  
//   &:not(:last-of-type) {
//     margin-bottom: ${theme.pxToRem(10)};
//   }

//   &:hover {
//     transform: ${editMode ? "translateX(0)" : "translateX(0.5rem)"};
//     transition: transform .10s;
//     cursor: ${editMode ? "" : "pointer"};
//   }
  
//   & > header {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     padding: ${theme.pxToRem(10)} 0;
//     margin-bottom: ${theme.pxToRem(10)};
//     border-bottom: 1px solid ${theme.colors['gray-3']};
    
//     p:first-of-type {
//       font-size: ${theme.pxToRem(18)};
//     }
//     p:last-of-type {
//       color: ${theme.colors['gray-2']}
//     }
//   }

//   div p {
//     font-size: ${theme.pxToRem(16)};
//     margin-bottom: ${theme.pxToRem(8)};
//   }
// }



@use "../../../scss/variables" as variables;
@use "../../../scss/functions" as functions;

.search-list {
  width: 597px;
  display: flex;
  display: flex;
  flex-direction: column;
}


.item-list {
  display: flex;
  flex-wrap: wrap;
  padding: functions.toRem(10);
  border: 1px solid variables.$borderOpaque;
  border-left: 8px solid;
  background: variables.$primaryB;
  transform: translateX(0);
  transition: transform .10s;
  opacity: 1;

  &.disabled {
    opacity: 0.2;
  }

  &.danger {
    border-left-color: variables.$negative;
  }

  &.success {
    border-left-color: variables.$positive;
  }
  
  &:not(:last-of-type) {
    margin-bottom: functions.toRem(10);
  }

  &:hover {
    transform: translateX(0.5rem);
    transition: transform .10s;
    cursor: pointer;

    &.edit-mode {
      transform: translateX(0);
      cursor: initial;
    }
  }
  
  & > header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: functions.toRem(10) 0;
    margin-bottom: functions.toRem(10);
    border-bottom: 1px solid variables.$borderOpaque;
    
    p:first-of-type {
      font-size: functions.toRem(18);
    }
    p:last-of-type {
      color: variables.$contentSecondary;
    }
  }

  div p {
    font-size: functions.toRem(16);
    margin-bottom: functions.toRem(8);
  }
}