.wrapper-actions {
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 600px;
  margin-bottom: 10px;

  &>div {
    justify-content: flex-start;
  }

  nav {
    display: flex;

    button {
      margin-left: 10px;
    }
  }
}

.user-login {
  nav {
    display: flex;
    justify-content: space-around;
    margin-top: 120px;

    button {
      width: 48%;
    }
  }
}