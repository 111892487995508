@use "./../../scss/variables" as variables;
@use "./../../scss/functions" as functions;

.base-layout {
  display: flex;
  flex-wrap: wrap;
  background: variables.$backgroundStateDisabled;
}

.base-layout__aside {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  padding: 0 functions.toRem(8);
  position: sticky;
  top: 0;
}

.base-layout__main {
  flex-grow: 1;
  width: calc(100% - 250px);
  background: variables.$primaryB;
  //padding: functions.toRem(40) functions.toRem(0);
}

.base-layout__content-header {
    position: sticky;
    display: flex;
    align-items: center;
    top: 60px;
    padding: functions.toRem(20) 0;
    background-color: variables.$primaryB;
    z-index: 1;

    &.active {
        box-shadow: 0 3px 2px -2px rgb(0 0 0 / 10%);
        transition: box-shadow .35s;
    }
}