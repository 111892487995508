@use "./../../scss/variables" as variables;
@use "./../../scss/functions" as functions;

.alert {
  width: 100%;
  display: inline-block;
  padding: functions.toRem(16);
  border-radius: functions.toRem(8);

  &.error {
    background: variables.$backgroundLightNegative;
    color: variables.$backgroundNegative;
  }

  &.success {
    background: variables.$backgroundLightPositive;
    color: variables.$backgroundPositive;
  }

  &.default {
    background: variables.$backgroundTertiary;
    color: variables.$primaryA;
  }
}