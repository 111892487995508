@use "../../../scss/variables" as variables;
@use "../../../scss/functions" as functions;

.register-form {
  width: 597px;
  background-color: variables.$primaryB;
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: functions.toRem(20);
  box-shadow: initial;

  header {
    margin-bottom: functions.toRem(20);
  }

  &.edit-mode {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: 1px solid variables.$primaryA;
    padding-bottom: functions.toRem(30);

    header {
      margin-bottom: functions.toRem(30);
    }

  }

  header,
  nav {
    width: 100%;
  }

  p {
    margin-bottom: functions.toRem(18);
  }

  nav {
    display: flex;
    justify-content: flex-end;
    margin-top: functions.toRem(30);
  }

  section {
    width: 48%;
  }
}

.feedback {
  width: 100%;
  display: flex;
  margin-top: functions.toRem(10);

  p {
    margin-bottom: 0 !important;
  }
}