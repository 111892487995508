@use "./../../scss/variables" as variables;
@use "./../../scss/functions" as functions;

.card {
  padding: functions.toRem(12);
  background: variables.$backgroundPrimary;
  color: variables.$contentTertiary;
  //box-shadow: #d3d3d3 0px 0px 10px -2px;
  //border: solid 1px #d3d3d3;
  border-radius: 4px;

  &.no-shadow {
    box-shadow: none;
  }

  &.variant--secondary {
    background: variables.$backgroundSecondary;
  }
}
