@use "../../scss/variables" as variables;
@use "../../scss/functions" as functions;
/*@use "./../../scss/variables" as variables;
@use "./../../scss/functions" as functions;*/
/*.page-login {
  width: 100vw;
  height: 100vh;
  padding: 0 functions.toRem(10);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.page-login__footer {
  display: flex;
  align-items: center;
  img {
    margin-left: functions.toRem(10);
  }
}

.form-login {
  width: 843px;
  height: 587px;
  padding: 0 functions.toRem(260);
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: variables.$font-primary;
}

.form-login__inputs {
  margin-bottom: functions.toRem(30);
}*/

.headerPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.legenda__content {
    display: flex;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
    height: 50px;
    //margin: 10px 10px 10px 10px;
    .itensLegenda__content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1em;
        border: 1px solid lightgray;
        border-radius: 15px;
        padding: 15px;
    }

    .contentImage {
        width: 50px;
        height: auto;
        display: block;
        margin: 0 auto;
    }

    .item {
        display: flex;
        align-items: center;
        gap: 5px
    }

    .itemRaioxTime, .itemPassagerProcess, .itemPassagerAlerts, .itemPassagerRandom {
        width: 25px;
        height: 10px;
        border-radius: 15px;
        background-color: #88af6e;
    }

    .itemPassagerProcess {
        background-color: #c095e3;
    }

    .itemPassagerAlerts {
        background-color: #ef5f5f;
    }

    .itemPassagerRandom {
        background-color: #617aef;
    }
}



.list-modulosOnline {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 functions.toRem(10);
    gap: functions.toRem(32);
    /*.card-monitoria {
        margin-right: functions.toRem(10);
        margin-left: functions.toRem(10);
        height: 320px;
        border-radius: 12px;
        border: 1px solid variables.$borderOpaque;
        margin-bottom: functions.toRem(20);
        width: 100%;

        &.positive {
            border-bottom: 3px solid variables.$positive;
            box-shadow: 1px 1px 6px 1px variables.$borderPositive;
        }

        &.negative {
            border-bottom: 3px solid variables.$negative;
            box-shadow: 1px 1px 6px 1px variables.$borderNegative;
        }

        &.offline {
            border-bottom: 3px solid variables.$offline;
            box-shadow: 1px 1px 6px 1px variables.$borderOffline;
        }

        @media screen and (min-width: variables.$md) {
            width: calc((100% / 3) - functions.toRem(20));
        }

        @media screen and (min-width: variables.$md) {
            width: calc((100% / 3) - functions.toRem(20));
        }
    }*/

    .modulos__content {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        padding: 10px;
        gap: 15px;
        /* border-top: 1px solid lightgray;
        border-left: 1px solid lightgray;
        border-right: 1px solid lightgray;*/
        background-color: #e5e5e5;
        margin-bottom: 0;
    }

    .modulo-container {
        display: flex;
        flex-shrink: 0;
        border: 2px solid transparent;
        flex-direction: column;
        align-items: center;

        &.normal {
            background-color: yellow;
        }

        &.positive {
            background-color: #34a44c;
        }

        &.negative {
            background-color: red;
        }

        .idModulo__content {
            margin-bottom: 5px;
            width: 100%;
            padding: 2px;
            border: 2px solid #30419b;
            /*background-color: #88af6e;*/
            border-radius: 10px;
            display: flex;
            justify-content: center;
        }

        .contentPassager {
            width: 100%;
            display: flex;
            gap: 1px;
            flex-direction: column;
        }

        .moduloOnline {
            width: 100%;
            //border: 1px solid #cfb4e5;
            padding: 5px;
            color: white;
            background-color: #34a44c;
            border-radius: 10px;
            display: flex;
            justify-content: center;
        }

        .moduloOffline {
            width: 100%;
            //border: 1px solid #ef5f5f;
            padding: 5px;
            color: white;
            background-color: #8B0000;
            border-radius: 10px;
            display: flex;
            justify-content: center;
        }

        .passagerRandom {
            width: 100%;
            border: 1px solid #617aef;
            background-color: #617aef;
            border-radius: 15px;
            display: flex;
            justify-content: center;
        }

        // .tooltip {
        //     position: relative;
        //     display: inline-block;
        //     border-bottom: 1px dotted black;
        //   }
          
        //   .tooltip .tooltiptext {
        //     visibility: hidden;
        //     width: 120px;
        //     bottom: 100%;
        //     left: 50%;
        //     margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
        //     background-color: black;
        //     color: #fff;
        //     text-align: center;
        //     border-radius: 6px;
        //     padding: 5px 0;
            
        //     /* Position the tooltip */
        //     position: absolute;
        //     z-index: 1;
        //     top: -5px;
        //     //left: 105%;
        //   }
          
        //   .tooltip:hover .tooltiptext {
        //     visibility: visible;
        //   }
    }

    .modulo-img {
        width: 60px;
        height: auto;
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
    }

    .legenda__content {
        display: flex;
        border: 1px solid lightgray;
        border-radius: 15px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        gap: 10px;
        margin-bottom: functions.toRem(2);

        .contItens {
            display: flex;
            gap: 10px;
            flex-direction: column;
        }

        .raioxNegative, .raioxPositive, .raioxNormal {
            width: 30px;
            height: 50px;
        }

        .raioxNegative {
            border: 5px solid red;
        }

        .raioxPositive {
            border: 5px solid #34a44c;
        }

        .raioxNormal {
            border: 5px solid yellow;
        }
    }
}

/*table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}*/

.table {
    //border: 1px solid black;
    border-collapse: collapse;
    padding: 0;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.linhasTabela {
    display: flex;
    justify-content: center;
}
