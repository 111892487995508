@use "./../../../scss/variables" as variables;
@use "./../../../scss/functions" as functions;

.menu {
  width: 250px;
  height: calc(100vh - 60px);
  // border-right: 1px solid variables.$backgroundSecondary;
  padding-top: functions.toRem(20);
  background: variables.$primaryB;
  z-index: 2;
  position: absolute;
  top: 60px;

  .accordion__item .btn i:not([class*="fa-chevron"]) {
    display: none;
  }

  &.closed {
    display: none;
    @media screen and (min-width: 800px) {
      display: block;
      width: 100px;

      .accordion__item {
        justify-content: center;
        .btn {
          padding: functions.toRem(16) 0;
          font-size: functions.toRem(12);
          text-align: center;
          display: flex;
          flex-direction: column;
          background: transparent;
          transition: .35s background;

          &:hover {
            background: variables.$backgroundSecondary;
            transition: .35s background;
          }
          i {
            font-size: functions.toRem(24);
            margin-bottom: functions.toRem(8);
            display: inline-block;
          }
        }
      }
    }
  }

  @media screen and (min-width: variables.$md) {
    position: sticky;
    background: transparent;
  }
}

.wrapper-filter {
  padding-bottom: functions.toRem(10);
  margin: 0 functions.toRem(10) functions.toRem(20) functions.toRem(10);
  border-bottom: 1px solid variables.$contentStateDisabled;

  .accordion {
    margin-top: functions.toRem(20);
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  list-style: none;

  .accordion {
    display: none;
    margin-top: 0;

    &.active {
      display: block;
    }

    .accordion__item {
      padding-left: functions.toRem(14);
      .btn {
        font-size: functions.toRem(14);
      }
    }
  }
}

.accordion__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  .text-helper {
    font-size: functions.toRem(12);
    padding: functions.toRem(0) functions.toRem(10);
    color: variables.$warning;
  }

  &.disabled {
    .btn {
      color: variables.$contentStateDisabled;
      pointer-events: none;
    }
  }

  .btn,
  .btn:hover {
    background: transparent;
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: variables.$contentSecondary;

    &:hover {
      color: variables.$contentPrimary;
    }
  }
  
}
