@use "./../../../scss/variables" as variables;
@use "./../../../scss/functions" as functions;

.main-header {
  display: flex;
  justify-content: space-between;
  padding: 0 functions.toRem(20);
  width: 100vw;
  height: 60px;
  // border-bottom: 1px solid variables.$backgroundSecondary;
  border-top: 6px solid;
  position: sticky;
  top: 0;
  background: variables.$backgroundStateDisabled;
  z-index: 2;
  .col-1,
  .col-2 {
    display: flex;
    align-items: center;
  }

  .col-1 {
    width: 170px;
  }

  .col-2 {
    width: 150px;
  }
}

.main-header__btn-toggle {
  width: 24px;
  height: 24px;
  border: 0;
  background: transparent;
  font-size: functions.toRem(20);
  color: variables.$contentTertiary;
  transition: 0.35s color;
  cursor: pointer;

  &:hover {
    color: variables.$contentSecondary;
    transition: 0.35s color;
  }
}

.main-header__brand {
  height: 40px;
  margin-left: functions.toRem(20);

  img {
    height: 100%;
  }
}

.user-avatar {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}

.user-name{
    display: flex;
    align-items: center;
    gap: 3px;
}
