@use "./../../scss/variables" as variables;
@use "./../../scss/functions" as functions;

.list-of-numbers {
  display: flex;
  justify-content: space-between;
  gap: functions.toRem(20);
  flex-wrap: wrap;

  .title-and-number {
    justify-content: flex-start;
  }

  @media screen and (min-width: variables.$md) {
    flex-wrap: nowrap;
  }
}

.list-of-numbers__item {
  width: 100%;
  padding: functions.toRem(16) functions.toRem(20);
  border-radius: functions.toRem(10);
  box-shadow: #45494f 0px 2px 4px -3px;

  @media screen and (min-width: variables.$md) {
    width: 50%;
  }
}

.title-and-number {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  
  strong {
    margin: 0 functions.toRem(10);
    font-size: functions.toRem(26);
  }
}

.consolidated__row-items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: functions.toRem(20);

  @media screen and (min-width: variables.$md) {
    justify-content: space-between;
  }
}

.consolidated__item {
  &:not(:last-of-type) {
    padding-right: functions.toRem(10);
  }
  
  @media screen and (min-width: variables.$md) {
    &:not(:last-of-type) {
      border-right: 1px solid variables.$borderOpaque;
    }
  }
  text-align: right;
}


.list-alerts {
  height: 200px;
  overflow-y: auto;
  margin-bottom: functions.toRem(20);

  p {
    margin-bottom: functions.toRem(20);
    padding: functions.toRem(10);
    border-bottom: 1px solid variables.$borderTransparent;
  }
}

.list-planos-acao {
  display: flex;
  flex-direction: column;
}

.list-planos-acao__item {
  display: flex;
  justify-content: space-between;
  padding: functions.toRem(10);
  background: variables.$backgroundSecondary;
  margin-bottom: 1px;
}

// TODO: Fazer disso um componente
.tab__nav {
  display: flex;

  .btn--tertiary,
  .btn--tertiary:hover {
    background: transparent;
    border-bottom: 1px solid variables.$borderOpaque;
  }

  .btn--primary,
  .btn--primary:hover,
  .btn--tertiary:hover {
    background: transparent;
    color: variables.$contentPrimary;
    border-bottom: 1px solid variables.$borderSelected;
  }
}

.tab__content {
  min-height: 340px;
}